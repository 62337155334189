import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Section from "components/Elements/Section"
import ActionButtons from "components/Elements/ActionButtons"
import Message from "components/Elements/Message"

import { AppContext } from "../../../../context/AppContext"
import { generateFormField } from "components/Elements/Form/services/form"
import { cssrsValidationSchema } from "../../utils/formData"
import cssrs from "../../utils/cssrs.json"

const CSSRS = ({ backRoute, nextRoute }) => {
  const { state, dispatch } = useContext(AppContext)
  const { selfRequest } = state

  const handleSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        selfRequest: {
          ...selfRequest,
          notes: values.notes || "",
          cssrs: values,
        },
      },
    })
    navigate(nextRoute)
  }

  const isWithinThePastMonth = selfRequest?.hasWishedToBeDead?.includes(
    "within the past month"
  )

  return (
    <Formik
      initialValues={selfRequest?.cssrs}
      validationSchema={cssrsValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors }) => {
        const cssrsQuestions = cssrs.map((formField) => {
          if (!formField?.referenceAnswer) {
            return generateFormField({
              formField,
              values,
              formFields: cssrs,
            })
          }
        })

        return (
          <Form key="CSSRS">
            <Section
              title={
                isWithinThePastMonth
                  ? "Within the past month..."
                  : "Tell us what kind of support you need"
              }
            >
              {cssrsQuestions}
            </Section>
            {Object.keys(errors).length > 0 ? (
              <Message color="warning">
                You may have missed some required fields. Please scan through
                the form and check if your information is complete.
              </Message>
            ) : null}
            <ActionButtons
              submit={{ label: "Choose Schedule" }}
              back={{ label: "Back to Personal Details", link: backRoute }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default CSSRS
