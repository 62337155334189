import React from "react"
import CSSRS from "components/Intake/SelfRequest/Assessment/CSSRS"

const Assessment = ({ location }) => {
  return (
    <CSSRS
      backRoute="/intake/self/history"
      nextRoute="/intake/self/schedule"
      location={location}
    />
  )
}

export default Assessment
