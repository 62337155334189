import React from "react"
import Layout from "components/Layout/Layout"
import Container from "components/Layout/Container"
import Assessment from "components/Intake/SelfRequest/Assessment"

export default ({ location }) => (
  <Layout
    title="Assessment"
    subtitle="Help us understand your needs better"
    seoTitle="Assessment"
    display={{ footer: false, helpCenterBanner: false }}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <Assessment location={location} />
    </Container>
  </Layout>
)
